<template>
  <div class="details">
    <div class="t">
      <card title="测评者信息" :icon="icon0" style="width: 560rem;">
        <div class="c1">
          <key-value style="padding:0;" space="0" class="top" label="测评名称" :key-width="120">{{ user_info.survey_name }}</key-value>
          <key-value style="padding:0;" space="0" label="测评者姓名" :key-width="120">{{ user_info.username }}</key-value>
          <key-value style="padding:0;" space="0" label="性别" :key-width="120">{{ user_info.gender }}</key-value>
          <key-value style="padding:0;" space="0" label="应聘校区" :key-width="120">{{ user_info.school_name }}</key-value>
          <key-value style="padding:0;" space="0" label="应聘岗位" :key-width="120">{{ user_info.position_name }}</key-value>
          <key-value style="padding:0;" space="0" label="联系方式" :key-width="120">{{ user_info.phone }}</key-value>
          <key-value style="padding:0;" space="0" label="完成时间" :key-width="120">{{ user_info.finish_at }}</key-value>
        </div>
      </card>
      <card title="《积极心理治疗量表》测评结果" :icon="icon1" style="width:1032rem;margin-left:24rem; " :is-pass="psychotherapy_result.result">
        <div class="c2">
          <table>
            <thead>
            <tr>
              <th width="122rem">维度</th>
              <th width="94rem">临床</th>
              <th width="94rem">非临床</th>
              <th width="94rem">测评者得分</th>
              <th>PERMA 元素的定义</th>
            </tr>
            </thead>
            <tbody align="center">
            <tr v-for="item in psychotherapy_result.list">
              <td>{{ item.wei_du }}</td>
              <td>{{ item.lin_chuang }}</td>
              <td>{{ item.fei_lin_chuang }}</td>
              <td><span :class="survey_score_class(item)">{{ item.survey_score }}</span></td>
              <td>{{ item.definition }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </card>
    </div>
    <card :icon="icon2" :is-pass="personality_result.result" title="《霍兰德职业人格测试》测评结果" style="margin-top: 24rem">
      <div class="c3">
        <div class="left">
          <div class="person">
            <div class="name">{{ user_info.username }}的人格序列</div>
            <div class="hint">以下是每种人格类型匹配程度具体得分:</div>
          </div>
          <div class="personality">
            <div class="item" v-for="(item,index) in personality">
              <div class="name" :style="{color:colors[item.char]}">{{ item.name }}</div>
              <el-progress :percentage="item.number*10" :color="colors[item.char]" stroke-width="10" :key="'personality'+index" :format="format" :text-color="colors[item.char]"></el-progress>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">
            匹配度最高的三种人格类型深入解析
          </div>
          <div class="content">
            <result v-for="item in topThree" :type="item.char"></result>
          </div>
        </div>
      </div>
    </card>
    <el-button type="primary" size="medium" style="margin: 25rem 0" @click="$router.back();">返回</el-button>
  </div>
</template>

<script>
import Card  from "./components/Card.vue"
import KeyValue from "@/components/KeyValue.vue";
import Result from "./components/Result.vue"
import icon0 from "@/assets/img/logistics/hr-management/icon-0.png"
import icon1 from "@/assets/img/logistics/hr-management/icon-1.png"
import icon2 from "@/assets/img/logistics/hr-management/icon-2.png"
export default {
  _config: {"route": {"path": "details", "meta": {"title": "查看", style:2}}},
  components:{KeyValue, Card,Result},
  data(){
    return{
      icon0,
      icon1,
      icon2,
      // 人格分数颜色
      colors:{
        I:"#4B84E6",
        A:"#6ECFB3",
        E:"#F6CC64",
        C:"#F6695A",
        R:"#755FF6",
        S:"#F6A05D",
      },
      personality:[
      ],
      user_info:{},
      psychotherapy_result:{},
      personality_result:{},
      topThree:[]
    }
  },
  mounted() {
    this.getData();
  },
  methods:{
    survey_score_class(item){
      let {single_char:type,survey_score} = item;


      /**
       * P（积极情绪）计分题目：61、66、71、76、81；若得分＞21则为【非临床】，14 ≤ 得分 ≤ 21则为【重点评估】，得分 ＜ 14则为【临床】
       * E（投入）计分题目：62、67、72、77、82；若得分＞21则为【非临床】，16 ≤ 得分 ≤ 21则为【重点评估】，得分 ＜ 16则为【临床】
       * R（关系）计分题目：63、68、73、78、83；若得分＞22则为【非临床】，14 ≤ 得分 ≤ 22则为【重点评估】，得分 ＜ 14则为【临床】
       * M（意义）计分题目：64、69、74、79、84；若得分＞19则为【非临床】，14 ≤ 得分 ≤ 19则为【重点评估】，得分 ＜ 14则为【临床】
       * A（成就）计分题目：65、70、75、80、85；若得分＞21则为【非临床】，18 ≤ 得分 ≤ 21则为【重点评估】，得分 ＜ 18则为【临床】
       *
       * default : 总分得分＞104则为【非临床】，76 ≤ 得分 ≤ 104则为【重点评估】，得分 ＜ 76则为【临床】
       * 临床：danger 非临床：success 重点评估：warning
       */
      switch (type){
        case "P":
          if (survey_score > 21) return "success";
          else if (survey_score >= 14 && survey_score <= 21) return "warning";
          else return "danger";
        case "E":
          if (survey_score > 21) return "success";
          else if (survey_score >= 16 && survey_score <= 21) return "warning";
          else return "danger";
        case "R":
          if (survey_score > 22) return "success";
          else if (survey_score >= 14 && survey_score <= 22) return "warning";
          else return "danger";
        case "M":
          if (survey_score > 19) return "success";
          else if (survey_score >= 14 && survey_score <= 19) return "warning";
          else return "danger";
        case "A":
          if (survey_score > 21) return "success";
          else if (survey_score >= 18 && survey_score <= 21) return "warning";
          else return "danger";
        default:
          if (survey_score > 104) return "success";
          else if (survey_score >= 76 && survey_score <= 104) return "warning";
          else return "danger";
      }
    },
    format(e){
      return (e/10)+"";
    },
    getData(){
      let loading = this.$loading({
        text: "正在加载中..."
      })
      this.$_axios2.get("api/survey/survey/pc-show?id="+this.$route.query.id,{logic:1}).then(res=>{
        let {user_info, psychotherapy_result, personality_result} = res.data;
        console.log(psychotherapy_result,personality_result)
        this.user_info = user_info;
        this.psychotherapy_result = psychotherapy_result;
        this.personality_result = personality_result;
        let top = [];
        this.personality = personality_result.list.map(item=>{
          let {name_cn_en,name_en,survey_score,single_char} = item;
          top.push({
            survey_score,
            char:single_char
          });
          return {
            name:name_cn_en+`（${name_en}）`,
            number:survey_score,
            char:single_char
          }
        });
        this.topThree = top.sort((a,b)=>b.survey_score-a.survey_score).slice(0,3);
      }).finally(()=>loading.close())
    }
  }
}
</script>

<style scoped lang="scss">
.details{
  div.t{
    display: flex;
    .c1{
      border-radius: 10rem;
      overflow:hidden;
      ::v-deep .t-key-value .key{
        padding: 14rem 20rem;
        background-color: #F7F7F7;
      }
      ::v-deep .t-key-value .value{
        padding: 14rem 80rem;
        flex:1;
        background: #FCFBFD;
      }
      .top{
        ::v-deep .key{
          color:white;
          background: linear-gradient( 175deg, #BAC6FD 0%, #9683FF 99%);
        }
        ::v-deep .value{
          color:white;
          background: #B1B5FE;
        }
      }
    }
    .c2{
      background: linear-gradient( 175deg, #BAC6FD 0%, #9683FF 99%);
      padding: 14rem;
      border-radius: 10rem;
      table{
        border-collapse:collapse;
        width: 100%;
        th{
          color: #FFFFFF;
          padding: 9rem 0;
        }
        tbody{
          td{
            padding: 9rem 0;
            background-color: white;
            border: none;
            border-spacing: 0;
            border-bottom: 1px solid #E7E1FF;
            span{
              width: 58rem;
              height: 26rem;
              line-height: 26rem;
              display: inline-block;
              text-align: center;
              border-radius: 13rem;
            }
            .success{
              color: #23C343;
              background-color: #23C34319;
            }
            .warning{
              color: #F6AD3C;
              background-color: #D8A12019;
            }
            .danger{
              color: #EA0000;
              background-color: #EA000019;
            }
          }
          tr:first-child td:first-child{
            border-top-left-radius: 8rem;
          }
          tr:first-child td:last-child{
            border-top-right-radius: 8rem;
          }
          tr:last-child td:first-child{
            border-bottom-left-radius: 8rem;
          }
          tr:last-child td:last-child{
            border-bottom-right-radius: 8rem;
          }

          tr td:first-child{
            color: #5D47D6;
          }
          tr td:last-child{
            padding: 9rem 16rem;
          }
          tr:last-child td{
            border-bottom: none;
          }
        }
      }
    }
  }
  .c3{
    display: flex;
    justify-content: space-between;
    .left{
      width: 460rem;
      margin-right: 122rem;
      .person{
        .name{
          color: #1C4EFD;
          font-size: 16rem;
          margin-bottom: 14rem;
        }
        .hint{
          color: #666;
        }
      }
      .personality{
        margin-top: 6rem;
        .item{
          margin-top: 20rem;
          .name{
            font-size: 16rem;
            margin-bottom: 8rem;
          }
        }
      }
    }
    .right{
      flex:1;
      .title{
        font-weight: 500;
        font-size: 24px;
        color: #222222;
        text-align: center;
        margin-bottom: 54rem;
      }
      .content{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

}
</style>