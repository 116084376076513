<template>
  <div class="result" :style="resultStyle">
    <div class="title" :style="{background:item.titleBg}">{{title}}</div>
    <div class="icon"><img :src="item.icon" alt=""></div>
    <p>性格特征</p>
    <div class="words">{{item.words}}</div>
    <div class="feature-title" :style="{color:item.color,backgroundColor:item.color+'30'}">表现特点</div>
    <ul>
      <li v-for="feature in item.feature"><span :style="{background:item.color}"></span><div class="feature">{{feature}}</div></li>
    </ul>
  </div>
</template>

<script>
import iconI from "@/assets/img/logistics/hr-management/icon-p-0.png"
import iconA from "@/assets/img/logistics/hr-management/icon-p-1.png"
import iconS from "@/assets/img/logistics/hr-management/icon-p-2.png"
import iconE from "@/assets/img/logistics/hr-management/icon-p-3.png"
import iconC from "@/assets/img/logistics/hr-management/icon-p-4.png"
import iconR from "@/assets/img/logistics/hr-management/icon-p-5.png"

export default {
  name: "result",
  props:{
    type:{
      type:String,
      validator(val) {
        return ["I","A","S","E","C","R"].includes(val)
      }
    }
  },
  computed:{
    item(){
      return this.dataMap[this.type];
    },
    title(){
      return this.type+" " + this.item.title;
    },
    resultStyle(){
      return {
        background:"linear-gradient( 179deg, #FFFFFD 17%, "+this.item.bg+" 96%)",
        borderColor:this.item.color
      }
    }
  },
  data() {
    return {
      dataMap:{
        I:{
          title:"研究型",
          icon:iconI,
          bg:"#BBDAFF",
          color:"#32528F",
          titleBg:'linear-gradient( 180deg, #84AEF6 3%, #2266DB 100%)',
          words:"分析、谨慎、批评、好奇、独立、聪明、内向、条理、谦逊、精确、理发、保守",
          feature:[
              "喜爱研究性的职业或情境，避免企业性的职业或情境。",
              "用研究的能力解决工作及其他方面的问题，即自觉、好学、自信，重视科学，但缺乏领导方面的才能。"
          ]
        },
        A:{
          title:"艺术型",
          icon:iconA,
          bg:"#CEFFF3",
          color:"#5BB89D",
          titleBg:'linear-gradient( 179deg, #87DBC1 5%, #52C1A2 99%)',
          words:"复杂、想象、冲动、独立、直觉、无秩序、情绪化、理想化、不顺从、有创意、富有表情、不重实际",
          feature:[
              "喜爱艺术性的职业或情境，避免传统性的职业或情境。",
              "富有表达能力和直觉、独立、具创意、不顺从（包括表演、写作、语言），并重视审美的领域。"
          ]
        },
        S:{
          title:"社会型",
          icon:iconS,
          bg:"#FAEBE1",
          color:"#F69E5B",
          titleBg:'linear-gradient( 180deg, #F6C884 4%, #F67D3B 99%)',
          words:"合作、友善、慷慨、助人、仁慈、负责、圆滑、善社交、善解人意、说服他人、理想主义",
          feature:[
              "喜爱社会型的职业或情境，避免实用性的职业或情境，并以社交方面的能力解决工作及其他方面的问题，但缺乏机械能力与科学能力。",
              "喜欢帮助别人、了解别人，有教导别人的能力，且重视社会与伦理的活动与问题。"
          ]
        },
        E:{
          title:"管理型",
          icon:iconE,
          bg:"#FAF6E1",
          color:"#F9C74F",
          titleBg:'linear-gradient( 180deg, #F6CC84 7%, #F6CA3B 100%)',
          words:"冒险、野心、独断、冲动、乐观、自信、追求享受、精力充沛、善于社交、获取注意、知名度",
          feature:[
              "喜欢企业性质的职业或环境，避免研究性质的职业或情境，会以企业方面的能力解决工作或其他方面的问题能力。",
              "有冲动、自信、善社交、知名度高、有领导与语言能力，缺乏科学能力，但重视政治与经济上的成就。"
          ]
        },
        C:{
          title:"常规型",
          icon:iconC,
          bg:"#FAE5E1",
          color:"#F94144",
          titleBg:'linear-gradient( 180deg, #F6A084 4%, #F6413B 99%)',
          words:"顺从、谨慎、保守、自控、服从、规律、坚毅、实际稳重、有效率、但缺乏想象力",
          feature:[
              "喜欢传统性质的职业或环境，避免艺术性质的职业或情境，会以传统的能力解决工作或其他方面的问题。",
              "喜欢顺从、规律、有文书与数字能力，并重视商业与经济上的成就。"
          ]
        },
        R:{
          title:"现实型",
          icon:iconR,
          bg:"#E5E1FA",
          color:"#2D3EDB",
          titleBg:'linear-gradient( 180deg, #9984F6 7%, #513BF6 99%)',
          words:"顺从、坦率、谦虚、自然、坚毅、实际、有礼、害羞、稳健、节俭",
          feature:[
              "喜爱实用性的职业或情境，以从事所喜好的活动，避免社会性的职业或情境。",
              "用具体实际的能力解决工作及其他方面的问题，较缺乏人际关系方面的能力。",
              "重视具体的事物，如金钱，权力、地位等。"
          ]
        }
      }
    }
  },
  methods: {},
  mounted() {

  }
}
</script>


<style scoped lang="scss">
.result{
  border-width: 1px;
  border-style: solid;
  border-radius: 10rem;
  padding:16rem 12rem;
  position: relative;
  width: 292rem;
  height: 370rem;
  .title{
    color:#fff;
    font-size:18rem;
    width: 132rem;
    height: 34rem;
    border-radius: 8rem;
    text-align: center;
    line-height: 34rem;
    position: absolute;
    top: -17rem;
    left:calc(50% - 66rem);
  }
  .icon{
    margin-top: 16rem;
    text-align: center;
    img{
      width: 40rem;
      height: 40rem;
    }
  }
  p{
    font-size: 18rem;
    color: #333333;
    font-weight: 500;
    margin: 16rem 0 18rem;
    text-align: center;
  }
  .words{
    font-size: 12rem;
    color: #555;
    line-height: 20rem;
    margin-bottom: 14rem;
  }
  .feature-title{
    font-size: 14rem;
    font-weight: 500;
    border-radius: 10rem;
    width: 114rem;
    height: 24rem;
    margin: 0 auto;
    text-align: center;
    line-height: 24rem;
  }
  ul {
    margin-top: 14rem;
    li{
      margin-bottom: 8rem;
      color:#555;
      font-size: 12rem;
      line-height: 20rem;
      font-weight: 400;
      display: flex;
      span{
        margin-top: 8rem;
        display: inline-block;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        margin-right: 8rem;
      }
      .feature{
        flex: 1;
      }
    }
  }
}
</style>