<template>
  <div class="card">
    <div class="header">
      <img :src="icon" alt="" class="icon">
      <div class="title">{{title}}</div>
      <div class="pass" v-if="isPass != null" v-html="pass">
      </div>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name:"card",
  props:{
    title:{
      type:String,
      default:""
    },
    icon:{
      type:[String,Object],
      default:""
    },
    isPass:{
      type:String,
      default:null,
      validator(value){
        return ["合格","不合格","重点评估"].includes(value);
      }
    }
  },
  computed:{
    pass(){
      let ip = this.isPass;
      let color = ip === "合格" ? "#23C343" : ip === "不合格"? "#F53F3F":"#F09D12";
      let bg = ip==="合格" ? "#E9F9EC" : ip === "不合格"? "#FCE5E5":"#FBF5E8";
      return `<div style="background-color: ${bg};color:${color};border-radius: 4rem;padding: 5rem 10rem;">${ip}</div>`
    }
  },
  data(){
    return {

    }
  },
  methods:{

  },
  mounted(){

  }
}
</script>


<style scoped lang="scss">
.card{
  padding: 18rem 24rem 24rem;
  background-color: white;
  border-radius: 8rem;
  .header{
    margin-bottom: 26rem;
    display: flex;
    align-items: center;
    .icon{
      width: 24rem;
      height:24rem;
    }
    .title{
      font-weight: 600;
      font-size: 20rem;
      color: #1E3AA2;
      margin-left: 8rem;
    }
    .pass{
      margin-left: 14rem;
      font-size: 14rem;
    }
  }
  .content{
    border-radius: 10rem;
  }
}
</style>